$(function() {
  // const colors = ["#30092b","#360b2e","#3d0c31","#440e34","#4a0f36","#511138","#58133b","#5f143d","#66163e","#6d1840","#751a41","#7c1d42","#831f43","#8a2244","#912544","#972844","#9e2c44","#a52f44","#ab3344","#b23743","#b83c43","#be4042","#c34540","#c94a3f","#ce4f3e","#d4543c","#d95a3a","#dd6038","#e26536","#e66b33","#ea7230","#ed782d","#f17e2a","#f48526","#f68b22","#f9921e","#fb9919","#fda012","#fea70a","#ffae00"];
  const colors = ['#000000', '#1c1c1c', '#1f1f1f', '#222222', '#252525', '#292929', '#2c2c2c', '#2f2f2f', '#323232', 
                    '#363636', '#3a3a3a', '#3e3e3e', '#414141', '#454545', '#494949', '#4c4c4c', '#515151', '#555555', 
                    '#595959', '#5d5d5d', '#626262', '#666666', '#6a6a6a', '#6f6f6f', '#737373', '#787878', '#7c7c7c', 
                    '#818181', '#858585', '#898989', '#8e8e8e', '#929292', '#979797', '#9b9b9b', '#9f9f9f', '#a4a4a4', 
                    '#a8a8a8', '#acacac', '#afafaf', '#ffffff']
  const queryString = window.location.href.split("/");
  let page = queryString[queryString.length-1].replace(".html","");
  $(".headerItem").removeClass("selected");
  if (page == "" || page == "about") {
    page = "about";
    $(".headerItem[rel='home']").addClass("selected");
  } else {
    $(".headerItem[rel='" + page + "']").addClass("selected");    
  }

  let cellSize = 30;
  let w = $("#visualContainer").width();
  let h = $("#visualContainer").height();
  let ballX = getRandomInt(w);
  let ballY = getRandomInt(h);
  let xVel = randomIntFromInterval(-2,2);
  let yVel = randomIntFromInterval(-2,2);
  let glowSize = 1000 / w; 
  let mx = getRandomInt(w);
  let my = getRandomInt(h);
  var arrows = {
    "index": ["←","↖","↑","↗","→","↘","↓","↙"],
    "videos": ["🍠","🍆","🍇","🍑","🍊","🍊","🍋","🧀"],
    "editing": ["🐓","🐓","🐥","🐥","🐣","🐣","🥚","🥚"],
    "other": ["😁","🙂","🥵","😡","👿","👿","👿","👿"].reverse()
  }

  let sketch = function(p) {
    var cnv;
    p.setup = function(){
      cnv = p.createCanvas(w, h);
      cnv.style('display', 'block');
      p.background(0);
      p.noStroke();
      w = $("#visualContainer").width();
      h = $("#visualContainer").height();
      resizeInteractive();
    }
    p.draw = function(){
      for (let x = 0; x < w/cellSize; x += 1) {
        for (let y = 0; y < h/cellSize; y += 1) {
          let color = distanceXY(x*cellSize,y*cellSize);
          let c1 = p.color(color[0]);
          c1.setAlpha(200);
          p.fill(c1);
          p.rect(x*cellSize,y*cellSize,cellSize,cellSize);
          let c2 = p.color(colors[0]);
          c2.setAlpha(color[2]);
          p.fill(c2);
          p.textAlign(p.CENTER, p.CENTER);
          let text = color[1];
          if (text != -1) {
            p.text(text,x*cellSize,y*cellSize, cellSize, cellSize);  
          }
        }
      }
      changeBall();

      p.mouseMoved = function() {
        if (p.mouseY < h - 20) {
          mx = p.mouseX;
          my = p.mouseY;  
        }
        
      }

    };





    function changeBall() {
      if (ballX >= w) {
        ballX = w -1;
        xVel = xVel * -1;
      }
      if (ballX <= 0) {
        ballX = 1;
        xVel = xVel * -1;
      }
      if (ballY >= h) {
        ballY = h;
        yVel = yVel * -1;
      }
      if (ballY <= 0) {
        ballY = 1;
        yVel = yVel * -1;
      }

      if (ballX < mx) {
        xVel = Math.sqrt(Math.abs(ballX - mx))*getRandomInt(3);
      } else if (ballX > mx) {
        xVel = -Math.sqrt(Math.abs(mx - ballX))*getRandomInt(3);
      }
      if (ballY < my) {
        yVel = Math.sqrt(Math.abs(ballY - my))*getRandomInt(3);
      } else if (ballY > my) {
        yVel = -Math.sqrt(Math.abs(my - ballY))*getRandomInt(3);
      }

      ballX += p.noise(ballX) * xVel;
      ballY += p.noise(ballY) * yVel;

    }
  }

  setInterval(function() {
    resizeInteractive();
    mx = getRandomInt(w);
    my = getRandomInt(h);
  },2000)
  
  function distanceXY(x1,y1) {
    var a = x1 - ballX;
    var b = y1 - ballY;
    var c = (250 + Math.random()*5) - Math.sqrt( a*a + b*b ) * glowSize;
    if (c < 0) {
      c = 0;
    }
    var key = c;
    var text = Math.round(Math.sqrt( a*a + b*b )/8);
    if (text > 30) {
      text = -1;
    }
    var alpha = 100;
    // if (page != "datagraphics") {
    //   let keyAmount;
    //   if (page == "other" || page == "videos" || page == "editing") {
    //     keyAmount = c / 255 * 360;
    //     alpha = c;
    //   } else {
    //     var angle = Math.atan2(b, a);
    //     var degrees = 180 * angle / Math.PI;
    //     keyAmount = (360 + Math.round(degrees)) % 360;

    //   }
    //   text = arrows[page][ Math.floor(keyAmount / 45) ]

    // }

    return [colors[Math.floor((key/255)* (colors.length-1) )], text, alpha];
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  $(window).resize(function() {
    resizeInteractive()
  });

  function resizeInteractive() {
   w = $("#visualContainer").width();
   h = $("#visualContainer").height();
   glowSize = 1400 / w; 
   if (glowSize < 0.5) {
    glowSize = 0.5;
  }
  cellSize = w / 50;
  if (cellSize < 20) {
    cellSize = 20;
  } else if (cellSize > 50) {
    cellSize = 50
  }
}

new p5(sketch, 'visualContainer');
});




